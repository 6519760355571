import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import {
    TableExportFormat,
    usePDFExportSetter,
} from 'src/components/table-exporter/TableExporterProvider';
import { InActiveData } from 'src/models/captures/alertsData.model';
import { DataType } from 'src/models/dataType.enum';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeinactivedatasearchValue,
    getInActiveDevices,
    getInActiveDevicesId,
    selectAlertsFilter,
    selectInActiveData,
    selectInActiveDataStatus,
    selectinactivedatasearchValue,
    selectcardDataIds,
    selectcardDataStatus,
    getallInActiveDevices,
    selectallInActiveData,
} from 'src/state/captures/capturesSlice';
import { PDFExport } from '../common-export-form/PDFExport';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { ColumnSelector } from '../column-selector/ColumnSelector';
import {
    DropdownOption, DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { Platform } from 'src/models/platforms.enum';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';

export function NonReportersListModal(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const inactivedata = useSelector(selectInActiveData);
    const inactivedataStatus = useSelector(selectInActiveDataStatus);
    const alertsFilter = useSelector(selectAlertsFilter);
    const pdfExportSetter = usePDFExportSetter();
    const inactivedatasearchValue = useSelector(selectinactivedatasearchValue);
    const [platformValue, setPlatformValue] = useState<Platform>();
    const carddataId = useSelector(selectcardDataIds);
    const cardDataStatus = useSelector(selectcardDataStatus);
    const allinactiveData = useSelector(selectallInActiveData);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber,
            entriesPerPage,
            searchValue: inactivedatasearchValue,
            details: true,
            platformValue,
        }));
    }

    const handleEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setEntriesPerPage(newEntriesPerPage);
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
            searchValue: inactivedatasearchValue,
            details: true,
            platformValue,
        }));
    };

    const getNRColumn = (data: InActiveData): React.ReactNode =>
    {
        if (data.nonReportDays === 'Not Reported')
        {
            return (
                <div>
                    Not Reported
                </div>
            );
        }

        const formattedFields = Object.entries(data)
            .filter(([key, value]) => key.startsWith('last'))
            .map(([key, value]) => (
                <div key={key}>
                    {key.charAt(4).toUpperCase() + key.slice(5)}
                    :
                    {typeof value === 'number' ? ` ${value} days \n` : ` ${value} \n`}
                </div>
            ));

        return (
            <div>
                {
                    formattedFields.length > 0 ? (
                        formattedFields
                    ) : (
                        <div>
                            Not Reported
                        </div>
                    )
                }
            </div>
        );
    };

    const columns = [
        {
            label: t('common.nouns.id'),
            size: 8,
            value: (data: InActiveData) => data.id,
        },
        {
            label: t('form.rcom-account'),
            size: 5,
            value: (data: InActiveData) => data.remoteCOMAccount,
        },
        {
            label: t('common.nouns.device'),
            size: 5,
            value: (data: InActiveData) => data.deviceAlias,
        },
        {
            label: t('common.nouns.client'),
            size: 5,
            value: (data: InActiveData) => data.client,
        },
        {
            label: t('form.email'),
            size: 5,
            value: (data: InActiveData) => data.clientEmail,
            disable: true,
        },
        {
            label: t('common.nouns.softwrae-version'),
            size: 3,
            value: (data: InActiveData) =>
                data.softwareVersion ? data.softwareVersion : '-',
        },
        {
            label: t('data-type.table.non-reporting-data'),
            size: 9,
            value: (data: InActiveData) => getNRColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (inactivedata)
        {
            pdfExportSetter({
                type: DataType.NON_REPORTER,
                recordIds: inactivedata.contents.map((item) => item.id),
            });
        }
    }, [inactivedata, pdfExportSetter]);

    function onChangeSelectFilter(value: string): void
    {
        dispatch(changeinactivedatasearchValue(value));
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage,
            searchValue: value,
            details: true,
            platformValue,
        }));
    }

    const debouncedValue = debounceSearch(onChangeSelectFilter, DEBOUNCE_DELAY_TIME);

    async function handlerAllPDFExport():
        Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getInActiveDevicesId({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            searchValue: inactivedatasearchValue,
            platformValue,
        }));
    }

    async function handlerAllXLSXExport():
        Promise<void>
    {
        setFormatAll('XLSX');
        dispatch(getallInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            searchValue: inactivedatasearchValue,
            platformValue,
            details: true,
            pageNumber: 0,
            entriesPerPage: 0,
        }));
    }

    function getPlatformOptions(): DropdownOption[] {
        return Object.entries(Platform)
            .filter(([name]) => name !== 'ChromeOsExtension')
            .map(([name, value]) => ({
                name,
                value,
            }));
    }
    function onPlatformSelect(value: unknown): void
    {
        setPlatformValue(value as Platform);
        dispatch(getInActiveDevices({
            agencyId: alertsFilter.agency,
            officerId: alertsFilter.officer,
            clientId: alertsFilter.client,
            pageNumber: 1,
            entriesPerPage,
            searchValue: inactivedatasearchValue,
            details: true,
            platformValue: value as Platform,
        }));
    }
    return (
        <>
            {inactivedata?.contents.length ? (
                <>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={carddataId}
                        idStatus={cardDataStatus}
                        data={DataType.NON_REPORTER}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                    />
                    <Button
                        variant="text"
                        startIcon={
                            formatAll === TableExportFormat.XLSX
                                ? <CircularProgress size="1rem" />
                                : <DownloadIcon />
                        }
                        disabled={formatAll === TableExportFormat.XLSX}
                        onClick={() =>
                        {
                            handlerAllXLSXExport();
                        }}
                    >
                        {t('common.button.all-to-xlsx')}
                    </Button>
                </>
            ) : null
            }

            <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                sx={{ mb: 2 }}
            >
                <Grid item xs={2} sx={{ ml: 3 }} >
                    <DropdownSelector
                        name="platform"
                        fullWidth
                        label={t('data-type.table.platform')}
                        options={getPlatformOptions()}
                        value={platformValue}
                        sx={{ width: '80%' }}
                        onChange={onPlatformSelect}
                        clearable={true}
                    />
                </Grid>

                <Grid item xs={2}>
                    <TextField
                        name="firstname"
                        fullWidth
                        label={t('data-type.table.search')}
                        autoComplete="off"
                        onChange={event => debouncedValue(event.target.value)}
                    />
                </Grid>
            </Grid>

            <DataTable<InActiveData>
                isLoading={inactivedataStatus === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={handleEntriesPerPageChange}
                datas={inactivedata}
                {...inactivedata}
            />
            {formatAll === TableExportFormat.XLSX &&
                allinactiveData &&
                allinactiveData?.contents.length > 0 && (
                <ColumnSelector
                    format={TableExportFormat.XLSX}
                    columns={columns}
                    data={{
                        contents: allinactiveData?.contents,
                        currentPage: 1,
                        numberOfEntries: 0,
                    }}
                    onClose={() => setFormatAll(undefined)}
                />
            )}
        </>
    );
}
