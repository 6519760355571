import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAdminDeviceFilters,
    changeAdminFilters,
    selectAdminFilters,
    selectAdminDeviceFilters,
    selectAdminSettingsMenu,
} from 'src/state/administration/administrationSlice';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdminSettings } from 'src/models/adminSettings.enum';
import {
    changeDeviceDetailsFilters,
    selectDeviceDetailsFilters,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { ADMIN_SETTINGS } from 'src/utils/routes';
import debounceSearch from 'src/utils/common';
import { DEBOUNCE_DELAY_TIME } from 'src/utils/environment';

export function AdminSettingSearchFilter(): React.ReactElement
{
    const dispatch = useDispatch();
    const deviceDetailsFilters = useSelector(selectDeviceDetailsFilters);
    const searchFilter = useSelector(selectAdminFilters);
    const searchDeviceFilter = useSelector(selectAdminDeviceFilters);
    const isDeviceSearch= window.location.pathname.split('/')[2]=== 'client'
        && window.location.pathname.split('/')[4] === 'devices';
    const { t } = useTranslation();
    const adminSettingMenu = useSelector(selectAdminSettingsMenu);
    const isDeviceInfoPage = window.location.pathname === ADMIN_SETTINGS &&
        adminSettingMenu === AdminSettings.DeviceInformation;
            const getSearchValue = (): string | undefined => {
            if (isDeviceSearch) {
                return searchDeviceFilter?.searchValue;
            }
            if (isDeviceInfoPage) {
                return deviceDetailsFilters?.searchValue;
            }
            return searchFilter?.searchValue;
        };
        
        const searchValue = getSearchValue();

    const [inputValue, setInputValue] = useState(searchValue ?? '');
    const debouncedOnChangeAdminFilter = useCallback(
        debounceSearch((value: string) =>
        {
            if (isDeviceSearch)
            {
                dispatch(
                    changeAdminDeviceFilters({
                        searchValue: value,
                    })
                );
            }
            else if (isDeviceInfoPage)
            {
                dispatch(
                    changeDeviceDetailsFilters({
                        searchValue: value,
                    })
                );
            }
            else
            {
                dispatch(
                    changeAdminFilters({
                        searchValue: value,
                    })
                );
            }
        }, DEBOUNCE_DELAY_TIME),
        [dispatch, isDeviceSearch, isDeviceInfoPage]
    );

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void =>
    {
        const value = event.target.value;
        setInputValue(value);
        debouncedOnChangeAdminFilter(value);
    };

    return (
        <FormControl>
            <TextField
                name="firstname"
                label={ t('data-type.table.search')}
                value={inputValue}
                autoComplete="on"
                onChange={handleChange}
            />
        </FormControl>
    );
}
