import React, { ReactNode, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    TextField,
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { noop } from 'src/utils/common';
import DownloadIcon from '@mui/icons-material/Download';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllToExportDatas,
    selectActiveData,
    selectActiveDataStatus,
    selectExportAllRecords,
} from 'src/state/captures/capturesSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
    getAgencyActiveDevice,
    getAgencyClients,
    getAgencyOfficers,
    selectAgencyActiveDevices,
    selectAgencyClients,
    selectAgencyDeleteStatus,
    selectAgencyOfficers,
} from 'src/state/administration/administrationSlice';
import { User } from 'src/models/userModels';
import { Device } from 'src/models/administration/deviceData.model';
import { Client } from 'src/models/administration/clientData.model';
import { DataType } from 'src/models/dataType.enum';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { ColumnSelector } from '../column-selector/ColumnSelector';

interface ConfirmationPanelProps {
    // eslint-disable-next-line
    data: any,
    DeleteModal?: () => void;
    DeleteSubmit?: (data?: string) => void;
    type: string;
    isStop?: boolean;
}

interface CardComponentProps {
    title: string;
    countActive: number;
    countInactive: number;
    icon: ReactNode;
    onClick: () => void;
}

function AgencyDetailsModel({
    data,
    DeleteModal = noop,
    DeleteSubmit = noop,
    type,
    isStop,
}: Readonly<ConfirmationPanelProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [deleteText, setDeleteText] = useState<string>();
    type contentType = User | Client | Device;
    const [column, setColumn] = useState<ColumnData<contentType>[]>([]);
    const [officerClicked, setOfficerClicked] = useState(false);
    const [clientClicked, setClientClicked] = useState(false);
    const [deviceClicked, setDeviceClicked] = useState(false);
    const activedata = useSelector(selectActiveData);
    const activedataStatus = useSelector(selectActiveDataStatus);
    // eslint-disable-next-line
   const exportAllData = useSelector(selectExportAllRecords);
    const [deviceEntriesPerPage, setDeviceEntriesPerPage] = useState(10);
    const [clientEntriesPerPage, setClientEntriesPerPage] = useState(10);
    const [officerEntriesPerPage, setOfficerEntriesPerPage] = useState(10);
    const officers = useSelector(selectAgencyOfficers);
    const clients = useSelector(selectAgencyClients);
    const activeDevices = useSelector(selectAgencyActiveDevices);
    const [officersActiveCount, setOfficersActiveCount] = useState(0);
    const [officersInactiveCount, setOfficersInactiveCount] = useState(0);
    const [clientsActiveCount, setClientsActiveCount] = useState(0);
    const [clientsInactiveCount, setClientsInactiveCount] = useState(0);
    const [activeDevicesCount, setActiveDevicesCount] = useState(0);
    const [inActiveDevicesCount, setInActiveDevicesCount] = useState(0);
    const deleteStatus = useSelector(selectAgencyDeleteStatus);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const agencyName = type === 'Agency'
        ? data.agencyName : data.firstName;
    const cardTitle =  type ==='Agency'
        ? t('reports.delete-agency-alert'):t('reports.delete-officer-alert');
        let exportData;

        if (officerClicked) {
            exportData = exportAllData?.contents as User[];
        } else if (clientClicked) {
            exportData = exportAllData?.contents as Client[];
        } else {
            exportData = exportAllData?.contents as Device[];
        }   
    function onDevicePageChange(pageNumber: number): void
    {
        dispatch(getAgencyActiveDevice({
            agencyId: data.id,
            pageNumber,
            entriesPerPage: deviceEntriesPerPage,
            officerId: type==='Agency'? undefined: data.id,
        }));

    }
    const handleDeviceEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setDeviceEntriesPerPage(newEntriesPerPage);
        dispatch(getAgencyActiveDevice({
            agencyId: data.id,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
            officerId: type==='Agency'? undefined: data.id,
        }));
    };

    function onOfficerPageChange(pageNumber: number): void
    {
        dispatch(getAgencyOfficers({
            agencyId: data.id,
            pageNumber,
            entriesPerPage: officerEntriesPerPage,
        }));
    }
    const handleOfficerEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setOfficerEntriesPerPage(newEntriesPerPage);
        dispatch(getAgencyOfficers({
            agencyId: data.id,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
        }));
    };

    function onClientPageChange(pageNumber: number): void
    {
        dispatch(getAgencyClients({
            agencyId: data.id,
            pageNumber,
            entriesPerPage: clientEntriesPerPage,
            officerId: type==='Agency'? undefined: data.id,
        }));
    }
    const handleClientEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setClientEntriesPerPage(newEntriesPerPage);
        dispatch(getAgencyClients({
            agencyId: data.id,
            pageNumber: 1,
            entriesPerPage: newEntriesPerPage,
            officerId: type==='Agency'? undefined: data.id,
        }));
    };
    useEffect(() =>
    {
        if(type ==='Agency')
        {
            dispatch(getAgencyOfficers({
                agencyId: data.id,
                pageNumber: 1,
                entriesPerPage: 10,
            }));
        }
        dispatch(getAgencyClients({
            agencyId: data.id,
            officerId: type==='Agency'? undefined: data.id,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        dispatch(getAgencyActiveDevice({
            agencyId: data.id,
            officerId: type==='Agency'? undefined: data.id,
            pageNumber: 1,
            entriesPerPage: 10,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() =>
    {
        setOfficersActiveCount(officers?.totalActive ? officers.totalActive : 0);
        setOfficersInactiveCount(officers?.totalInActive ? officers.totalInActive : 0);
        setClientsActiveCount(clients?.totalActive ? clients.totalActive : 0);
        setClientsInactiveCount(clients?.totalInActive ? clients.totalInActive : 0);
        setActiveDevicesCount(activeDevices?.totalActive ? activeDevices.totalActive : 0);
        setInActiveDevicesCount(activeDevices?.totalInActive ? activeDevices.totalInActive : 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officers, clients, activeDevices]);

    const handleCardClick = (cardType: string): void =>
    {
        if (cardType === 'officer')
        {
            setOfficerClicked(true);
        }
        else if (cardType === 'client')
        {
            setClientClicked(true);
        }
        else
        {
            setDeviceClicked(true);
        }
    };

    const officerAndDeviceStatus = (data: User | Device): JSX.Element =>
    {
        const textStatus: string = (data.activated && data.enabled)
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: theme => ( (data.activated && data.enabled)
                        ? theme.palette.success.main
                        : theme.palette.error.main),
                }}
            >
                {textStatus}
            </Box>
        );
    };

    const clientStatus = (data: Client): JSX.Element =>
    {
        const textStatus: string = data.enabled
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: theme => (data.enabled
                        ? theme.palette.success.main
                        : theme.palette.error.main),
                }}
            >
                {textStatus}
            </Box>
        );
    };

    const officercolumns: ColumnData<User>[] = [
        {
            label: t('common.nouns.id'),
            size: 5,
            value: (data: User) => data.id,
        },
        {
            label: t('form.firstname'),
            size: 4,
            value: (data: User) => data.firstName,
        },
        {
            label: t('form.lastname'),
            size: 5,
            value: (data: User) => data.lastName,
        },
        {
            label: t('form.email'),
            size: 3,
            value: (data: User) =>
                data.email,
        },
        {
            label: t('common.nouns.status'),
            size: 2,
            value: (data: User) =>
            {
                if (!data.enabled)
                {
                    return t('common.status.disabled');
                }
                if (!data.activated)
                {
                    return t('common.status.inactive');
                }
                return t('common.status.active');
            },
            format: (data: User) => officerAndDeviceStatus(data),
        },
        {
            label: t('form.role'),
            size: 4,
            value: (data: User) =>
                data.role,
        },
    ];

    const renderButtonContent = ():ReactNode =>
    {
        if (deleteStatus === RequestStatus.InProgress)
        {
            return <CircularProgress size={'1.5em'} />;
        }
        return isStop ? t('common.actions.stop') : t('common.actions.delete');
    };

    const clientcolumns = [
        {
            label: t('common.nouns.id'),
            size: 5,
            value: (data: Client) => data.id,
        },
        {
            label: t('form.firstname'),
            size: 4,
            value: (data: Client) => data.firstName,
        },
        {
            label: t('form.lastname'),
            size: 5,
            value: (data: Client) => data.lastName,
        },
        {
            label: t('form.email'),
            size: 3,
            value: (data: Client) =>
                data.email,
        },
        {
            label: t('common.nouns.status'),
            size: 2,
            value: (data: Client) =>
            {
                if (!data.enabled)
                {
                    return t('common.status.disabled');
                }
                return t('common.status.active');
            },
            format: (data: Client) => clientStatus(data),
        },
        {
            label: t('form.rComNumber'),
            size: 4,
            value: (data: Client) => data.remoteCOMAccount,
        },
    ] as ColumnData<User | Client | Device>[];
    const devicecolumns = [
        {
            label: t('common.nouns.id'),
            size: 5,
            value: (data: Device) => data.id,
        },
        {
            label: t('data-type.filters.client'),
            size: 4,
            value: (data: Device) => data.clientName,
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: Device) => data.alias,
        },
        {
            label: t('data-type.table.device-type'),
            size: 4,
            value: (data: Device) => data.platform,
        },
        {
            label: t('common.nouns.status'),
            size: 2,
            value: (data: Device) =>
            {
                if (!data.enabled)
                {
                    return t('common.status.disabled');
                }
                if (!data.activated)
                {
                    return t('common.status.inactive');
                }
                return t('common.status.active');
            },
            format: (data: Device) => officerAndDeviceStatus(data),
        },
        {
            label: t('data-type.table.last-connect-date'),
            size: 3,
            dateFormat: (device: Device) =>
            {
                if (device.lastConnectAt)
                {
                    return timeZoneFormat(new Date(device.lastConnectAt), USER_TIME_ZONE);
                }
                else
                {
                    return ('-');
                }
            },
        },
    ]as ColumnData<User | Client | Device>[];

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        const officerModel = officers && officerClicked;
        const clientModel = clients && clientClicked;
        const deviceModel = activeDevices && deviceClicked;
        if (tableExportFormat === TableExportFormat.PDF)
        {
            if (officerModel)
            {
                const officerIds = data.id;

                await TableExporter.asPDF({
                    type: DataType.AGENCY_OFFICER,
                    recordIds: [officerIds],
                });
            }
            else if (clientModel)
            {
                const clientIds = data.id;

                if( type === 'Officer')
                {
                    await TableExporter.asPDF({
                        type: DataType.OFFICER_CLIENTS,
                        recordIds: [clientIds],
                    });
                }
                else
                {
                    await TableExporter.asPDF({
                        type: DataType.AGENCY_CLIENT,
                        recordIds: [clientIds],
                    });
                }
            }
            else if (deviceModel)
            {
                const clientIds = data.id;

                if( type === 'Officer')
                {
                    await TableExporter.asPDF({
                        type: DataType.OFFICER_DEVICES,
                        recordIds: [clientIds],
                    });
                }
                else
                {
                    await TableExporter.asPDF({
                        type: DataType.AGENCY_DEVICES,
                        recordIds: [clientIds],
                    });
                }
            }
            else if(type === 'Officer')
            {
                const officerIds = data.id;
                await TableExporter.asPDF({
                    type: DataType.OFFICER_CLIENTS,
                    recordIds: [officerIds],
                });

                await TableExporter.asPDF({
                    type: DataType.OFFICER_DEVICES,
                    recordIds: [officerIds],
                });
            }
            else
            {
                const officerIds = data.id;

                await TableExporter.asPDF({
                    type: DataType.AGENCY_OFFICER,
                    recordIds: [officerIds],
                });
                const clientIds = data.id;

                await TableExporter.asPDF({
                    type: DataType.AGENCY_CLIENT,
                    recordIds: [clientIds],
                });

                await TableExporter.asPDF({
                    type: DataType.AGENCY_DEVICES,
                    recordIds: [clientIds],
                });
            }


        }
        setFormat(undefined);
    }

    const CardComponent: React.FC<CardComponentProps> = ({
        title,
        countActive,
        countInactive,
        icon,
        onClick,
    }) => (
        <Card
            sx={{
                width: type==='Agency'? 250 : 350,
                height: 130,
                mt: 4,
                boxShadow: 5,
                mb: 2,
                position: 'relative',
                color: '#9e9e9e',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <CardContent>
                <Typography variant="h4" component="div">
                    {title}
                </Typography>
                <Typography component="div" style={{ fontSize: '0.9rem' }}>
                    {t('data-type.details.active-inactive')}
                </Typography>
                <Typography variant="h2">
                    {countActive}
                    /
                    {countInactive}
                </Typography>
                <Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '20%',
                            right: 4,
                            transform: 'translateY(-50%)',
                            width: '3rem',
                            height: '3rem',
                        }}
                    >
                        {icon}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
    async function handlerXLSXExport(): Promise<void>
    {
        if (officerClicked) {
            dispatch(getAllToExportDatas({
                pdfExport: true,
                reportAgencyId: type === 'Officer' ? undefined : [data.id],
                reportOfficerId: type === 'Officer' ? [data.id] : undefined,
                dataType: DataType.AGENCY_OFFICER,
                pageNumber: 1,
                entriesPerPage: officers?.numberOfEntries ?? 10,
            }))
            setColumn(officercolumns);
        }
        else if (clientClicked) {
            dispatch(getAllToExportDatas({
                pdfExport: true,
                reportAgencyId: type === 'Officer' ? undefined : [data.id],
                reportOfficerId: type === 'Officer' ? [data.id] : undefined,
                dataType: DataType.AGENCY_CLIENT,
                pageNumber: 1,
                entriesPerPage: clients?.numberOfEntries ?? 10,
            }))
            setColumn(clientcolumns);
        }
        else if (deviceClicked) {
            dispatch(getAllToExportDatas({
                pdfExport: true,
                reportAgencyId: type === 'Officer' ? undefined : [data.id],
                reportOfficerId: type === 'Officer' ? [data.id] : undefined,
                dataType: DataType.AGENCY_DEVICES,
                pageNumber: 1,
                entriesPerPage: activeDevices?.numberOfEntries ?? 10,
            }))
            setColumn(devicecolumns);
        }
        setFormatAll('XLSX');
    }
    return (
        <>
            <span style={{ maxWidth: '10px' }}>
                <Alert severity="warning" style={{ marginBottom: '10px' }}>
                    {cardTitle}
                </Alert>
            </span>
            {(type==='Agency' || type==='Officer') ? (
                <>
                <Button
                    variant="text"
                    sx={{
                      
                        marginLeft:(officerClicked || clientClicked || deviceClicked)? '72%':'85%',
                    }}
                    startIcon={
                        TableExporter.loading &&
                        format === TableExportFormat.PDF
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    onClick={() =>
                        handlerPDFExport(TableExportFormat.PDF)
                    }
                >
                    All to PDF
                </Button>
                {(officerClicked || clientClicked || deviceClicked)?
                 <Button
                    variant="text"
                   
                    startIcon={
                        TableExporter.loading &&
                        format === TableExportFormat.PDF
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    onClick={() =>
                        handlerXLSXExport()
                    }
                >
                    {t('common.button.all-to-xlsx')}
                </Button>:''
                }                </>
            ):''}


            {!officerClicked && !clientClicked && !deviceClicked && (
                <>
                    <section
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginRight: type==='Agency'? '10px': '7%',
                            marginLeft: type==='Agency'? '':'7%',
                            marginTop: '-2%',
                        }}
                    >
                        {type==='Agency'? (
                            <CardComponent
                                title={t('data-type.details.officers')}
                                countActive={officersActiveCount}
                                countInactive={officersInactiveCount}
                                icon={(
                                    <LocalPoliceIcon
                                        sx={{
                                            fontSize: '6rem',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                )}
                                onClick={() => handleCardClick('officer')}
                            />
                        ):''}

                        <CardComponent
                            title={t('data-type.details.clients')}
                            countActive={clientsActiveCount}
                            countInactive={clientsInactiveCount}
                            icon={(
                                <SupervisedUserCircleIcon
                                    sx={{
                                        fontSize: '6rem',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            )}
                            onClick={() => handleCardClick('client')}
                        />

                        <CardComponent
                            title={t('data-type.details.devices')}
                            countActive={activeDevicesCount}
                            countInactive={inActiveDevicesCount}
                            icon={(
                                <PhonelinkOutlinedIcon
                                    sx={{
                                        fontSize: '6rem',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            )}
                            onClick={() => handleCardClick('device')}
                        />
                    </section>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        style={{ marginTop: '70px' }}
                    >
                        <WarningIcon fontSize="small" />

                        <span style={{ position: 'relative', marginLeft: '5px' }}>
                            Type the
                            {' '}
                            {type}
                            {' '}
                            name
                            {' '}
                            <b>
                                {agencyName}
                            </b>
                            {' '}
                            to delete

                        </span>

                        <TextField
                            fullWidth
                            autoComplete="off"
                            value={deleteText}
                            onChange={(e) => setDeleteText(e.target.value)}
                            required
                            autoFocus
                        />

                    </Grid>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap={1}
                        padding="1rem 0"
                    >
                        <Button
                            type="button"
                            variant="contained"
                            onClick={DeleteModal}
                        >
                            {t('common.actions.cancel')}
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            disabled={
                                ((data.agencyName !== deleteText)&&(type==='Agency')) ||
                                ((data.firstName !== deleteText&&(type==='Officer')))
                            }
                            color={
                                deleteStatus === RequestStatus.InProgress ?
                                    'inherit' : 'error'
                            }
                            onClick={() => DeleteSubmit(deleteText)}
                        >
                            {renderButtonContent()}
                        </Button>
                    </Stack>
                </>
            )}
            {(officerClicked || clientClicked || deviceClicked) && (
                <Button
                    type="button"
                    variant="contained"
                    sx={{ marginBottom: '1%' }}
                    onClick={() =>
                    {
                        setOfficerClicked(false);
                        setClientClicked(false);
                        setDeviceClicked(false);
                    }}
                >
                    <KeyboardDoubleArrowLeftIcon />
                    {t('common.actions.back')}
                </Button>
            )}
            {officerClicked ? (
                <DataTable<User>
                    isLoading={activedataStatus === RequestStatus.InProgress}
                    columns={officercolumns}
                    onPageChange={onOfficerPageChange}
                    entriesPerPage={officerEntriesPerPage}
                    onChangeEntriesPerPage={handleOfficerEntriesPerPageChange}
                    {...officers}
                />
            ) : null}
            {clientClicked ? (
                <DataTable<Client>
                    isLoading={activedataStatus === RequestStatus.InProgress}
                    columns={clientcolumns}
                    onPageChange={onClientPageChange}
                    entriesPerPage={clientEntriesPerPage}
                    onChangeEntriesPerPage={handleClientEntriesPerPageChange}
                    datas={activedata}
                    {...clients}
                />
            ) : null}
            {deviceClicked ? (
                <DataTable<Device>
                    isLoading={activedataStatus === RequestStatus.InProgress}
                    columns={devicecolumns}
                    onPageChange={onDevicePageChange}
                    entriesPerPage={deviceEntriesPerPage}
                    onChangeEntriesPerPage={handleDeviceEntriesPerPageChange}
                    datas={activedata}
                    {...activeDevices}
                />
            ) : null}
             {formatAll === TableExportFormat.XLSX &&
                            exportAllData &&
                            exportAllData?.contents.length > 0 && (
                            <ColumnSelector
                                format={TableExportFormat.XLSX}
                                columns={column}
                                data={{
                                    contents: exportData,
                                    currentPage: 1,
                                    numberOfEntries: 0,
                                }}
                                onClose={() => setFormatAll(undefined)}
                            />
                        )}
        </>
    );
}


export default AgencyDetailsModel;
